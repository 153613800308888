import DoorsComponent from "../components/DoorsComponent";
import MenuBar from "../components/MenuBar";
import AccountMenu from "../components/UserMenu";

const Dashboard = () => {

    return (
        <div>
            <div className="w-[100%]"><AccountMenu className/></div>
            <MenuBar/>
            <DoorsComponent type={'Tus puertas'}/>
        </div>
    );
}

export default Dashboard 