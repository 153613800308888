import { useState } from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings'
import { Link, useLocation } from 'react-router-dom'

export default function MenuBar() {
  const location = useLocation()
  const [activePage, setActivePage] = useState(location.pathname)

  const handleClick = (path) => {
    setActivePage(path)
  }

  const getButtonClass = (path) => {
    const baseClass = "w-full h-full flex flex-col items-center py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200"
    return activePage === path ? `${baseClass} bg-gray-300 text-gray-900` : baseClass
  }

  return (
    <nav className="bg-white border-t border-gray-300 fixed bottom-0 left-0 right-0 z-50">
      <ul className="flex justify-around">
        <li className="flex-1">
          <Link
            to="/"
            className={getButtonClass('/')}
            onClick={() => handleClick('/')}
          >
            <HomeIcon className="h-6 w-6" />
            <span className="text-xs mt-1">Tus Puertas</span>
          </Link>
        </li>
        <li className="flex-1">
          <Link
            to="/devices"
            className={getButtonClass('/devices')}
            onClick={() => handleClick('/devices')}
          >
            <FavoriteBorderIcon className="h-6 w-6" />
            <span className="text-xs mt-1">Tus Dispositivos</span>
          </Link>
        </li>
        <li className="flex-1">
          <Link
            to="/settings"
            className={getButtonClass('/settings')}
            onClick={() => handleClick('/settings')}
          >
            <SettingsIcon className="h-6 w-6" />
            <span className="text-xs mt-1">Config.</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}