
import axios from "../axiosConfig";

export default class DoorService {
  urlBase = "";
  token = "";

  constructor() {
    this.urlBase =  process.env.REACT_APP_ACCESS_CORE_URL;
  }

  //curl -X GET http://localhost:8000/api/doors?
  //response [{"id":1,"device_id":1,"relay_number":1,"name":"Torre Nico","enabled":1,"created_at":"2024-10-07T17:39:36.000000Z","updated_at":"2024-10-07T17:39:36.000000Z"}]
  getDoors = (params) => {
    return axios.get(this.urlBase + "/api/doors?" + params);
  }

  //curl -X POST http://localhost:8000/api/createAccessRequest -d '{"door_id":1}'
  createAccessRequest = (door_id) => {
    return axios.post(this.urlBase + "/api/createAccessRequest", { "door_id":door_id });
  };

}