import React from 'react';
import { Box, Typography, Modal as MuiModal, IconButton, Stack, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const GenerateCode = ({ open, handleClose, guestCode }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(guestCode).then(() => {
      alert('Código copiado al portapapeles');
    }).catch(err => {
      console.error('Error al copiar el código: ', err);
    });
  };

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', backgroundColor: 'white', padding: 6, boxShadow: 24, zIndex: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold' }}>
            {guestCode === "" ? "Generando..." : "Tú código de invitado es:"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack
          sx={{ width: '100%', alignItems: 'center' }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <Typography variant="body1" className="text-center text-lg font-mono">
            {guestCode}
          </Typography>
          {guestCode !== "" && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<ContentCopyIcon />}
              onClick={handleCopy}
            >
              Copiar código
            </Button>
          )}
        </Stack>
        {guestCode !== "" && (
          <Typography variant="subtitle2" className="text-center" sx={{ marginTop: 2 }}>
            Compártelo con el administrador de la puerta.
          </Typography>
        )}
      </Box>
    </MuiModal>
  );
};

export default GenerateCode;