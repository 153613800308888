import { Box, Typography } from "@mui/material"
import DeviceService from "../services/DeviceService";
import { useState, useEffect } from 'react'
import DeviceMenu from "./DeviceColapseMenu";




const DevicesComponent = () => {
   const deviceService = new DeviceService()
    const [devices, setDevices] = useState([])
    const getDevices = async () => {
        try {
            const response = await deviceService.getDoors()
            setDevices(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDevices()
    }, [])

    return (
    <div className="flex flex-col h-screen bg-gray-100">
      <main className="flex-1 overflow-y-auto p-4 pt-16">
        <Typography variant="h5" className="font-bold mb-4">Tus Dispositivos</Typography>
        <Box>
          {devices.map((row, index) => (
            <Box key={index} className="flex items-center mb-3 bg-white rounded-lg shadow">
              <Box className="flex-grow p-3 flex items-center justify-between">
                <Typography className="font-medium">{row.name}</Typography>
              </Box>
              <DeviceMenu id={row.id} />
            </Box>
          ))}
        </Box>
      </main>
    </div>
    )
}

export default DevicesComponent