import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Stack, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoorService from '../services/DoorService';
import ThreePointsMenu from './DoorsColapseMenu';
import DoorShareService from '../services/DoorShareService';
import GenerateCode from './GenerateCode';

const DoorsComponent = ({ type }) => {
  const doorService = new DoorService();
  const doorShareService = new DoorShareService();

  const [doors, setDoors] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(null);
  const [processingDoor, setProcessingDoor] = useState(null);
  const [loadingDoors, setLoadingDoors] = useState(true);
  const [code, setCode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const showAlertMessage = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

  const createAccessRequest = async (doorId) => {
    setProcessingDoor(doorId);
    try {
      const response = await doorService.createAccessRequest(doorId);
      showAlertMessage("success", "Abierto correctamente");
      console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setProcessingDoor(null);
    }
  };

  const getDoors = async () => {
    setLoadingDoors(true);
    if (type === 'Tus puertas') {
      try {
        const response = await doorService.getDoors();
        setDoors(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDoors(false);
      }
    }
    if (type === 'Tus Favoritos') { }
  };

  const generateCode = async () => {
    setCode("");
    try {
      const response = await doorShareService.generateCode();
      setCode(response.code);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddClick = () => {
    generateCode();
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getDoors();
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {showAlert && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>
          <Stack sx={{ width: "100%" }} spacing={2} mb={2}>
            <Alert severity={alertSeverity} onClose={() => setShowAlert(false)}>
              {alertMessage}
            </Alert>
          </Stack>
        </div>
      )}
      <main className="flex-1 overflow-y-auto p-4 pt-16">
        <Box className="flex items-center justify-between">
          <Typography variant="h5" className="font-bold mb-4">{type}</Typography>
          <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box>
          {loadingDoors && <Typography className="text-center">Cargando...</Typography>}
          {!loadingDoors && doors.length === 0 && <Typography className="text-center">No hay puertas disponibles</Typography>}
          {doors.map((row, index) => (
            <Box
              key={index}
              className={`flex items-center mb-3 rounded-lg shadow ${processingDoor === row.id ? 'bg-gray-300' : 'bg-white'}`}
            >
              <Box className="flex-grow p-3 flex items-center justify-between">
                <Typography onClick={() => createAccessRequest(row.id)} className="font-medium w-[90%]">{row.name}</Typography>
              </Box>
              <ThreePointsMenu id={row.id}/>
            </Box>
          ))}
        </Box>
      </main>
      <GenerateCode open={modalOpen} handleClose={handleClose} guestCode={code} />
    </div>
  );
};

export default DoorsComponent;