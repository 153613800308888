import './App.css';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Devices from './pages/Devices';
import Login from './pages/Login';
import Callback from './components/Callback';
import PrivateRoute from './components/PrivateRoute';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import AdminDevices from './pages/AdminDevices';
import AddDoor from './pages/AddDoor';


function App() {
  return (
  <ThemeProvider theme={theme}>
    <Routes>
      <Route path="/" element={<PrivateRoute element={Dashboard} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/devices" element={<PrivateRoute element={Devices} />} />
      <Route path="/devices/admin" element={<AdminDevices />} />
      <Route path="/devices/add" element={<AddDoor />} />
    </Routes>
  </ThemeProvider>
  );
}

export default App;