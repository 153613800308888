import { Box, Typography, IconButton } from "@mui/material"
import DeviceService from "../services/DeviceService";
import { useState, useEffect} from 'react'
import DeviceDoorsMenu from "./DeviceDoorsColapse";
import AddIcon from '@mui/icons-material/Add';
import { Link} from 'react-router-dom'



const DevicesComponentDoors = () => {
   const deviceService = new DeviceService()
    const [devices, setDevices] = useState([])

   
   
   


    const getDevicesDoors = async () => {
        try {
            const response = await deviceService.getDoors()
            setDevices(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDevicesDoors()
    }, [])

    return (
    <div className="flex flex-col h-screen bg-gray-100">
      <main className="flex-1 overflow-y-auto p-4 pt-16">
      <Box className="flex items-center justify-between">
          <Typography variant="h5" className="font-bold mb-4">Puertas de:</Typography>
          <Link to="/devices/add">
          <IconButton>
            <AddIcon />
          </IconButton>
          </Link>
        </Box>
        <Box>
          {devices.map((row, index) => (
            <Box key={index} className="flex items-center mb-3 bg-white rounded-lg shadow">
              <Box className="flex-grow p-3 flex items-center justify-between">
                <Typography className="font-medium">{row.name}</Typography>
              </Box>
              <DeviceDoorsMenu id={row.id} />
            </Box>
          ))}
        </Box>
      </main>
    </div>
    )
}
export default DevicesComponentDoors