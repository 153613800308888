import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Callback = () => {
    const location = useLocation();

    useEffect(() => {
        const middlewareUrl = process.env.REACT_APP_MIDDLEWARE_URL;
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        if (code) {
            axios.post(middlewareUrl+'/exchange-token', new URLSearchParams({
                code,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                localStorage.setItem('access_token', response.data.access_token);
                // Redirect to the home page or another page
                window.location.href = '/';
            })
            .catch(error => {
                console.error('Error fetching the token:', error);
            });
        }
    }, [location]);

    return (
        <div>
            <p>Redirecting...</p>
        </div>
    );
};

export default Callback;