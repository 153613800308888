import AddDoorForm from "../components/AddDoorForm"
import MenuBar from "../components/MenuBar"
import AccountMenu from "../components/UserMenu"

const AddDoor = () => {

    return (
        <div>
            <div className="w-[100%]"><AccountMenu className/></div>
            <AddDoorForm/>
            <MenuBar/>
        </div>
    )
}
export default AddDoor