import DevicesComponent from "../components/DevicesComponent";
import MenuBar from "../components/MenuBar";
import AccountMenu from "../components/UserMenu";


const Devices = () => {

    return (
        <div> 
            <div><AccountMenu/></div>
            <MenuBar/>
            <DevicesComponent/>
        </div>
    )
}

export default Devices