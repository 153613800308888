import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import AuthorizeDoor from '../services/DoorShareService';
import Alert from '@mui/material/Alert';
import SendIcon from '@mui/icons-material/Send';

export default function ShareDoor({ id, open, onClose }) {
  const authorizeDoor = new AuthorizeDoor();
  const [forSubmit, setForSubmit] = useState({
    door_id: id,
    code: ""
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [responseSeverity, setResponseSeverity] = useState("success");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForSubmit({
      ...forSubmit,
      [name]: value
    });
  };

  const handleAuthorize = async (e) => {
    e.preventDefault();
    console.log(forSubmit);
    try {
      const response = await authorizeDoor.authorizeDoor(forSubmit.code, forSubmit.door_id);
      console.log(response);
      setResponseMessage(response.message);
      setResponseSeverity("success");
    } catch (error) {
      console.log(error.response ? error.response.data : error.message);
      setResponseMessage(error.response.data.error);
      setResponseSeverity("error");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box onSubmit={handleAuthorize} component="form" sx={{ position: 'absolute', top: '25%', left: '50%', transform: 'translate(-50%, -50%)', width: '85%', backgroundColor: 'white', padding: 6, boxShadow: 24, zIndex: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold' }}>Ingresa el código del invitado:</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack
          sx={{ width: '100%' }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            variant="filled"
            size="small"
            name="code"
            value={forSubmit.code}
            onChange={handleInputChange}
            fullWidth
          />
        </Stack>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          sx={{ marginTop: 2 }}
          type="submit"
        >
          Compartir
        </Button>
        {responseMessage && (
          <Alert severity={responseSeverity} sx={{ marginTop: 2 }}>
            {responseMessage}
          </Alert>
        )}
      </Box>
    </Modal>
  );
}