import axios from "axios";

export default class DeviceService {
  urlBase = "";
  token = "";

  constructor() {
    this.urlBase =  process.env.REACT_APP_ACCESS_CORE_URL;
  }

getDoors = (params) => {
    return axios.get(this.urlBase + "/api/devices?" + params);
  }

}