import DevicesComponentDoors from "../components/DeviceDoors";
import AccountMenu from "../components/UserMenu";
import MenuBar from "../components/MenuBar";

const AdminDevices = () => {


    return (
    <div>
    <div className="w-[100%]"><AccountMenu className/></div>
    <DevicesComponentDoors/>
    <MenuBar/>
    </div>)
}

export default AdminDevices;