import { Box , Typography, TextField, Button} from "@mui/material"
import { useState } from "react";


const AddDoorForm = () => {
    const [forSubmit, setForSubmit] = useState({
        code: ""
      });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForSubmit({
          ...forSubmit,
          [name]: value
        });
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(forSubmit);
    }


    return(
        <Box onSubmit={handleSubmit} component="form" className="flex flex-col bg-gray-100 w-full p-4 h-screen">
        <main className="w-full max-w-[80%] pt-4">
          <Typography variant="h6" className="font-bold mb-4 text-left">
            Agregar puerta a nombre:
          </Typography>
          <Typography sx={{marginTop: "20px"}} variant="subtitle1" className="font-bold mb-2 text-left">
            Nombre de la puerta:
          </Typography>
          <TextField
            sx={{marginLeft: "10%"}}
            required
            id="outlined-required"
            label="Required"
            fullWidth
            className="mb-8 mx-[10%]"
            name="code"
            onChange={handleInputChange}
            value={forSubmit.code}
          />
          <Typography variant="subtitle1" className="font-bold mb-4 text-left mb-[30px]">
            Puerto
          </Typography>
        </main>
        <div className="flex justify-center gap-4 w-full max-w-[60%] mb-8 mx-[20%] my-[30px]">
          
            <Box
              sx={{
                width: 'calc(25% - 12px)', // 25% width minus gap
                paddingBottom: 'calc(25% - 12px)', // Makes it square
                borderRadius: 1,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            />
            <Box
              sx={{
                width: 'calc(25% - 12px)', // 25% width minus gap
                paddingBottom: 'calc(25% - 12px)', // Makes it square
                borderRadius: 1,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            />
            <Box
              sx={{
                width: 'calc(25% - 12px)', // 25% width minus gap
                paddingBottom: 'calc(25% - 12px)', // Makes it square
                borderRadius: 1,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            />
            <Box
              sx={{
                width: 'calc(25% - 12px)', // 25% width minus gap
                paddingBottom: 'calc(25% - 12px)', // Makes it square
                borderRadius: 1,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            />
        </div>
        <Button type="submit" variant="contained" className="mt-4">
          Añadir Puerta
        </Button>
      </Box>
)
}

export default AddDoorForm